export class SortDatesValueConverter {
  toView(array, config) {
    let factor = (config.direction || 'ascending') === 'ascending' ? 1 : -1;
    return array.sort((a, b) => {
      return (
        (new Date(b.chargeScheduledDate).getTime() - new Date(a.chargeScheduledDate).getTime()) *
        factor
      );
    });
  }
}
