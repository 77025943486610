/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { BillingAgreement } from './BillingAgreement';
import type { Charge } from './Charge';
import type { Client } from './Client';
import type { Product } from './Product';
import type { User } from './User';

export type Subscription = {
    id: number;
    status: Subscription.status;
    statusMessage: string;
    pausePaperDelivery: boolean;
    finishSubscriptionToken: string;
    finishSubscriptionExpire: string;
    expireDate: string;
    invalidatedDate?: string;
    cancelledDate: string;
    lastProductChangeDate: string;
    address: Address;
    invoiceEmail?: string;
    campaignProduct?: Product;
    campaignUsedDate?: string;
    campaignExpire?: string;
    campaignNextProduct?: Product;
    companyStreetAddress?: string;
    companyStreetAddress2?: string;
    companyPostalCode?: string;
    companyRegion?: string;
    companyCountry?: string;
    companyName?: string;
    companyOrgNumber?: string;
    companyEmail?: string;
    companyOrderer?: string;
    companyReference?: string;
    companyUseEhf: boolean;
    user: User;
    billingAgreement: BillingAgreement;
    charges: Array<Charge>;
    product: Product;
    client: Client;
};

export namespace Subscription {

    export enum status {
        ACTIVE = 'active',
        PAUSED = 'paused',
        CANCELLED = 'cancelled',
        FAILED = 'failed',
        PENDING = 'pending',
    }


}

