import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';

export class DateTimeFormatValueConverter {
  toView(value) {
    if (!value) {
      return '-';
    }

    return format(parseISO(value), 'd. MMMM yyyy - HH:mm', {
      locale: nb,
    }).toLowerCase();
  }
}
