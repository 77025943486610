/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BillingAgreement } from './BillingAgreement';
import type { Product } from './Product';
import type { Subscription } from './Subscription';

export type InvoiceForApprovalProduct = {
    id: number;
    externalChargeId: string;
    status: InvoiceForApprovalProduct.status;
    amount: number;
    currency: string;
    paymentGateway: InvoiceForApprovalProduct.paymentGateway;
    receiptId?: string;
    billingAgreement: BillingAgreement;
    subscription: Subscription;
    product: Product;
    chargeScheduledDate?: string;
    chargeProcessedDate?: string;
    chargeCompletedDate?: string;
    captureScheduledDate?: string;
    captureProcessedDate?: string;
    captureCompletedDate?: string;
    netsVerifyScheduledDate?: string;
    netsVerifyCompletedDate?: string;
    netsInvalidReminderSent: boolean;
    netsVerifyBulkId?: string;
    netsChecked?: boolean;
    isRetry: boolean;
    name: string;
    intervalType: InvoiceForApprovalProduct.intervalType;
};

export namespace InvoiceForApprovalProduct {

    export enum status {
        PENDING = 'pending',
        DUE = 'due',
        CHARGED = 'charged',
        FAILED = 'failed',
        REFUNDED = 'refunded',
        PARTIALLY_REFUNDED = 'partially_refunded',
        RESERVED = 'reserved',
        CANCELLED = 'cancelled',
        PROCESSING = 'processing',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
    }

    export enum paymentGateway {
        VIPPS = 'vipps',
        STRIPE = 'stripe',
        NETS = 'nets',
        INVOICE = 'invoice',
        FREE = 'free',
        INVOICE_COMPANY = 'invoiceCompany',
    }

    export enum intervalType {
        MINUTE = 'minute',
        HOUR = 'hour',
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }


}

