/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserChargeProduct } from './UserChargeProduct';

export type UserCharge = {
    id: number;
    status: UserCharge.status;
    amount: number;
    paymentGateway: UserCharge.paymentGateway;
    chargeScheduledDate?: string;
    chargeCompletedDate?: string;
    captureCompletedDate?: string;
    product: UserChargeProduct;
};

export namespace UserCharge {

    export enum status {
        PENDING = 'pending',
        DUE = 'due',
        CHARGED = 'charged',
        FAILED = 'failed',
        REFUNDED = 'refunded',
        PARTIALLY_REFUNDED = 'partially_refunded',
        RESERVED = 'reserved',
        CANCELLED = 'cancelled',
        PROCESSING = 'processing',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
    }

    export enum paymentGateway {
        VIPPS = 'vipps',
        STRIPE = 'stripe',
        NETS = 'nets',
        INVOICE = 'invoice',
        FREE = 'free',
        INVOICE_COMPANY = 'invoiceCompany',
    }


}

