/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClientDiImportBatchSubscriptionChange } from './ClientDiImportBatchSubscriptionChange';
import type { DiBatchDetails } from './DiBatchDetails';

export type ClientDiImportBatch = {
    id: number;
    status: ClientDiImportBatch.status;
    batchId: string;
    scheduledDate: string;
    completedDate: string;
    batchBody: DiBatchDetails;
    subscriptionChanges: Array<ClientDiImportBatchSubscriptionChange>;
};

export namespace ClientDiImportBatch {

    export enum status {
        DONE_IMPORTING_WITH_ERRORS = 'DONE_IMPORTING_WITH_ERRORS',
        UPLOADED = 'UPLOADED',
        DONE_IMPORTING = 'DONE_IMPORTING',
    }


}

