/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { User } from './User';

export type UserExternalId = {
    id: number;
    user: User;
    provider: UserExternalId.provider;
    externalId: string;
    createdOn: string;
    updatedOn: string;
};

export namespace UserExternalId {

    export enum provider {
        VIPPS = 'vipps',
    }


}

