/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DiBatchDetailsImportError } from './DiBatchDetailsImportError';

export type DiBatchDetailsTransaction = {
    id: string;
    created: string;
    finished: string;
    lastUpdate: string;
    status: DiBatchDetailsTransaction.status;
    index: number;
    importErrors: Array<DiBatchDetailsImportError>;
};

export namespace DiBatchDetailsTransaction {

    export enum status {
        DONE_IMPORTING_WITH_ERRORS = 'DONE_IMPORTING_WITH_ERRORS',
        UPLOADED = 'UPLOADED',
        DONE_IMPORTING = 'DONE_IMPORTING',
    }


}

