/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Client } from './Client';

export type BillingAgreement = {
    id: number;
    agreementId: string;
    agreementIdForUpdate?: string;
    agreementIdForUpdateExpire?: string;
    netsSubscriptionId?: string;
    start?: string;
    stop?: string;
    status: BillingAgreement.status;
    paymentGateway: BillingAgreement.paymentGateway;
    hasNetsError: boolean;
    client: Client;
};

export namespace BillingAgreement {

    export enum status {
        ACTIVE = 'active',
        PENDING = 'pending',
        STOPPED = 'stopped',
        EXPIRED = 'expired',
        ERROR = 'error',
    }

    export enum paymentGateway {
        VIPPS = 'vipps',
        STRIPE = 'stripe',
        NETS = 'nets',
        INVOICE = 'invoice',
        FREE = 'free',
        INVOICE_COMPANY = 'invoiceCompany',
    }


}

