export class PriceFormatValueConverter {
  toView(value) {
    var formatter = new Intl.NumberFormat('nb-NO', {
      style: 'currency',
      currency: 'NOK',
      minimumFractionDigits: 0,
    });

    // Price is given in "øre", therefore divided by 100
    const priceValue = value / 100;
    return formatter.format(priceValue);
  }
}
