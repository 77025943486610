/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Product } from './Product';

export type UserChargeProduct = {
    id: number;
    name: string;
    description: string;
    price: number;
    free: boolean;
    currency: string;
    intervalCount: number;
    intervalType: UserChargeProduct.intervalType;
    digital: boolean;
    paper: boolean;
    stripeProductId: string;
    campaign: boolean;
    campaignDurationDays?: number;
    campaignQuarantineDays?: number;
    campaignNextProduct: Product;
    hidden: boolean;
    business: boolean;
    groupEnabled: boolean;
    groupMaxMembers: number;
    groupInvoiceByMembers: boolean;
    groupAutoCreate: boolean;
};

export namespace UserChargeProduct {

    export enum intervalType {
        MINUTE = 'minute',
        HOUR = 'hour',
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }


}

