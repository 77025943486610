import { PLATFORM } from 'aurelia-pal';
import { FrameworkConfiguration } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./value-converters/date-format'),
    PLATFORM.moduleName('./value-converters/date-time-format'),
    PLATFORM.moduleName('./value-converters/time-ago-format'),
    PLATFORM.moduleName('./value-converters/price-format'),
    PLATFORM.moduleName('./value-converters/sort-dates'),
    PLATFORM.moduleName('./value-converters/charge-status-format'),
    PLATFORM.moduleName('./value-converters/phone-number-format'),
  ]);
}
