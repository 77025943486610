/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserUserGroupAdmin } from './UserUserGroupAdmin';
import type { UserUserGroupSubscription } from './UserUserGroupSubscription';
import type { UserUserGroupUser } from './UserUserGroupUser';

export type UserUserGroup = {
    id: number;
    name: string;
    type: UserUserGroup.type;
    admin: UserUserGroupAdmin;
    subscription: UserUserGroupSubscription;
    users: Array<UserUserGroupUser>;
    usersCount: number;
};

export namespace UserUserGroup {

    export enum type {
        PERSONAL = 'personal',
        GENERATED = 'generated',
    }


}

