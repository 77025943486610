/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateProductDto = {
    name: string;
    description: string;
    price: number;
    intervalCount: number;
    intervalType: CreateProductDto.intervalType;
    digital: boolean;
    paper: boolean;
    business: boolean;
    hidden: boolean;
    groupEnabled: boolean;
    groupMaxMembers: number;
    groupAutoCreate: boolean;
    campaign: boolean;
    campaignDurationDays: number;
    campaignQuarantineDays: number;
    campaignNextProductId: number;
};

export namespace CreateProductDto {

    export enum intervalType {
        MINUTE = 'minute',
        HOUR = 'hour',
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }


}

