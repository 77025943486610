/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExternalService } from './ExternalService';
import type { User } from './User';

export type Client = {
    id: number;
    name: string;
    email: string;
    phone: string;
    streetAddress: string;
    streetAddress2: string;
    postalCode: string;
    region: string;
    country: string;
    slug: string;
    authUrl: string;
    website: string;
    hostUrl: string;
    iconUrl: string;
    logoUrl: string;
    backgroundUrl: string;
    loginTitle: string;
    loginDescription: string;
    termsUrl: string;
    language: Client.language;
    tagManagerId: string;
    publicationMatrix: string;
    publicationPrice: number;
    notifyNewSubscriptions: boolean;
    externalServices: Array<ExternalService>;
    admins: Array<User>;
    createdOn: string;
    updatedOn: string;
};

export namespace Client {

    export enum language {
        NB = 'nb',
        NN = 'nn',
    }


}

