/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DiBatchDetailsImportError } from './DiBatchDetailsImportError';
import type { DiBatchDetailsTransaction } from './DiBatchDetailsTransaction';

export type DiBatchDetails = {
    id: string;
    batchId: string;
    status: DiBatchDetails.status;
    source: string;
    type: string;
    version: string;
    noOfTransactions: number;
    percentageDone: number;
    created: string;
    finished: string;
    lastUpdate: string;
    webhookUrl: string;
    webhookDoneCalled: string;
    webhookErrorCalled: string;
    importErrors: Array<DiBatchDetailsImportError>;
    transactions: Array<DiBatchDetailsTransaction>;
};

export namespace DiBatchDetails {

    export enum status {
        DONE_IMPORTING_WITH_ERRORS = 'DONE_IMPORTING_WITH_ERRORS',
        UPLOADED = 'UPLOADED',
        DONE_IMPORTING = 'DONE_IMPORTING',
    }


}

