import { PLATFORM } from 'aurelia-pal';
import { Aurelia } from 'aurelia-framework';
import environment from '../config/environment.json';

export function configure(aurelia: Aurelia): void {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName('resources/index'))
    .feature(PLATFORM.moduleName('validation/index'))
    .feature(PLATFORM.moduleName('components/global/index'));

  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  }

  //Uncomment the line below to enable animation.
  aurelia.use
    .plugin(PLATFORM.moduleName('aurelia-animator-css'))
    //if the css animator is enabled, add swap-order="after" to all router-view elements
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'));

  //Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin('aurelia-html-import-template-loader');

  aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
