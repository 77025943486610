/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';

export type UserSubscription = {
    id: number;
    status: UserSubscription.status;
    validTo?: string;
    product: {
        id?: number;
        name?: string;
        price?: number;
        intervalType?: UserSubscription.intervalType;
        digital?: boolean;
        paper?: boolean;
        groupEnabled?: boolean;
        groupMaxMembers?: number;
        groupInvoiceByMembers?: boolean;
        groupAutoCreate?: boolean;
    };
    client: {
        id?: number;
        name?: string;
        iconUrl?: string;
        slug?: string;
    };
    billingAgreement: {
        id?: number;
        paymentGateway?: UserSubscription.paymentGateway;
        hasNetsError?: boolean;
    };
    user: {
        id?: number;
        firstName?: string;
        lastName?: string;
        fullName?: string;
        email?: string;
        phone?: string;
        streetAddress?: string;
        streetAddress2?: string;
        postalCode?: string;
        region?: string;
    };
    expireDate?: string;
    createdOn: string;
    address: Address;
    pausePaperDelivery: boolean;
    balance?: number;
    lastProductChangeDate?: string;
    campaignProduct: {
        id?: number;
        name?: string;
    };
    campaignNextProduct: {
        id?: number;
        name?: string;
        price?: number;
    };
    campaignExpire?: string;
    companyStreetAddress?: string;
    companyStreetAddress2?: string;
    companyPostalCode?: string;
    companyRegion?: string;
    companyCountry?: string;
    companyName?: string;
    companyOrgNumber?: string;
    companyEmail?: string;
    companyOrderer?: string;
    companyReference?: string;
    companyUseEhf: boolean;
};

export namespace UserSubscription {

    export enum status {
        ACTIVE = 'active',
        PAUSED = 'paused',
        CANCELLED = 'cancelled',
        FAILED = 'failed',
        PENDING = 'pending',
    }

    export enum intervalType {
        MINUTE = 'minute',
        HOUR = 'hour',
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }

    export enum paymentGateway {
        VIPPS = 'vipps',
        STRIPE = 'stripe',
        NETS = 'nets',
        INVOICE = 'invoice',
        FREE = 'free',
        INVOICE_COMPANY = 'invoiceCompany',
    }


}

