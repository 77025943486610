import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { nb } from 'date-fns/locale';
export class DateFormatValueConverter {
  toView(value, formatString = 'd. MMMM yyyy') {
    try {
      const formatted = formatInTimeZone(
        parseISO(value),
        'Europe/Oslo',
        formatString,
        {
          locale: nb,
        }
      );
      return formatted.toLowerCase();
    } catch (error) {
      return '-';
    }
  }
}
