import { format, formatDistance, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';

export class TimeAgoFormatValueConverter {
  toView(value) {
    if (!value) {
      return '-';
    }

    return formatDistance(parseISO(value), new Date(), {
      locale: nb,
      addSuffix: true,
    }).toLowerCase();
  }
}
