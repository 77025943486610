/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Change = {
    id: number;
    type: Change.type;
    scheduledDate: string;
    completedDate: string;
    newState: Record<string, any>;
    createdOn: string;
};

export namespace Change {

    export enum type {
        NEW = 'new',
        CHANGE = 'change',
        CANCEL = 'cancel',
        PAUSE = 'pause',
        RESUME = 'resume',
    }


}

