/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateSubscriptionDto = {
    productId: number;
    paymentGateway: CreateSubscriptionDto.paymentGateway;
    streetAddress: string;
    streetAddress2: string;
    postalCode: string;
    region: string;
    companyName?: string;
    companyOrgNumber?: string;
    companyStreetAddress?: string;
    companyStreetAddress2?: string;
    companyPostalCode?: string;
    companyRegion?: string;
    companyUseEhf?: boolean;
    companyEmail?: string;
    companyOrderer?: string;
    companyReference?: string;
};

export namespace CreateSubscriptionDto {

    export enum paymentGateway {
        VIPPS = 'vipps',
        STRIPE = 'stripe',
        NETS = 'nets',
        INVOICE = 'invoice',
        FREE = 'free',
        INVOICE_COMPANY = 'invoiceCompany',
    }


}

