/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Client } from './Client';
import type { SubscriptionChange } from './SubscriptionChange';

export type DiImportBatch = {
    id: number;
    status: DiImportBatch.status;
    batchId: string;
    scheduledDate: string;
    completedDate: string;
    requestBody: string;
    batchBody: string;
    subscriptionChanges: SubscriptionChange;
    client: Client;
};

export namespace DiImportBatch {

    export enum status {
        DONE_IMPORTING_WITH_ERRORS = 'DONE_IMPORTING_WITH_ERRORS',
        UPLOADED = 'UPLOADED',
        DONE_IMPORTING = 'DONE_IMPORTING',
    }


}

