export class PhoneNumberFormatValueConverter {
  toView(value) {
    if (!value) {
      return;
    }
    if (value.length === 10) {
      return `${value.substring(2, 5)} ${value.substring(5, 7)} ${value.substring(7, 10)}`;
    } else if (value.length === 8) {
      return `${value.substring(0, 3)} ${value.substring(3, 5)} ${value.substring(5, 8)}`;
    }

    return value;
  }
}
