export class ChargeStatusFormatValueConverter {
  toView(paymentStatus) {
    switch (paymentStatus) {
      case 'cancelled':
        return 'Kansellert';
      case 'charged':
        return 'Betalt';
      case 'due':
        return 'Til betaling';
      case 'failed':
        return 'Mislyktes';
      case 'partially_refunded':
        return 'Delvis refundert';
      case 'pending':
        return 'Venter';
      case 'processing':
        return 'Under betaling';
      case 'refunded':
        return 'Refundert';
      case 'reserved':
        return 'Reservert';
    }
  }
}
