/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateSubscriptionByClientDto = {
    pausePaperDelivery: boolean;
    userId: number;
    productId: number;
    paymentGateway: UpdateSubscriptionByClientDto.paymentGateway;
    companyName?: string;
    companyOrgNumber?: string;
    companyStreetAddress?: string;
    companyStreetAddress2?: string;
    companyPostalCode?: string;
    companyRegion?: string;
    companyUseEhf?: boolean;
    companyEmail?: string;
    streetName: string;
    streetNumber: string;
    entrance: string;
    floor: string;
    flat: string;
    co: string;
    postalCode: string;
    city: string;
};

export namespace UpdateSubscriptionByClientDto {

    export enum paymentGateway {
        VIPPS = 'vipps',
        STRIPE = 'stripe',
        NETS = 'nets',
        INVOICE = 'invoice',
        FREE = 'free',
        INVOICE_COMPANY = 'invoiceCompany',
    }


}

