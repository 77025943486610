/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Client } from './Client';
import type { ClientDiImportBatchSubscriptionChangeUser } from './ClientDiImportBatchSubscriptionChangeUser';
import type { DiImportBatch } from './DiImportBatch';
import type { Subscription } from './Subscription';

export type ClientDiImportBatchSubscriptionChange = {
    id: number;
    user: ClientDiImportBatchSubscriptionChangeUser;
    type: ClientDiImportBatchSubscriptionChange.type;
    scheduledDate: string;
    completedDate: string;
    batchId: string;
    newState: string;
    diImportBatch?: DiImportBatch;
    subscription: Subscription;
    client: Client;
};

export namespace ClientDiImportBatchSubscriptionChange {

    export enum type {
        NEW = 'new',
        CHANGE = 'change',
        CANCEL = 'cancel',
        PAUSE = 'pause',
        RESUME = 'resume',
    }


}

