/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Subscription } from './Subscription';
import type { User } from './User';
import type { UserUserGroupInvitationUserGroupAdmin } from './UserUserGroupInvitationUserGroupAdmin';

export type UserUserGroupInvitationUserGroup = {
    id: number;
    name: string;
    admin: UserUserGroupInvitationUserGroupAdmin;
    type: UserUserGroupInvitationUserGroup.type;
    users: Array<User>;
    subscription: Subscription;
    isActive: boolean;
    createdOn: string;
    updatedOn: string;
};

export namespace UserUserGroupInvitationUserGroup {

    export enum type {
        PERSONAL = 'personal',
        GENERATED = 'generated',
    }


}

